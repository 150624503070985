import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { LIST_FADE_ANIMATION } from '../../utils/list.animation';


declare var Push: any;
declare var $: any;



@Component({
	selector: 'vr-toolbar-notifications',
	templateUrl: './toolbar-notifications.component.html',
	styleUrls: ['./toolbar-notifications.component.scss'],
	animations: [...LIST_FADE_ANIMATION]
})
export class ToolbarNotificationsComponent implements OnInit {
	
	isOpen: boolean;
	notifications: any[];
	demoTriggers = 0;
	
	constructor(
    private cd: ChangeDetectorRef
	) { }
	
	ngOnInit() {
		
		
		
		/*
			setInterval(function(){ 
			Push.create("Sanatec App", {
			body: "Questa è una web app!",
			icon: "./assets/img/logo_dark2x.png",
			timeout: 1000,
			onClick: function() {
            console.log(this);
			}
			});
			//code goes here that will be run every 5 seconds.    
		}, 5000);*/
		
		
		
		this.notifications = [
			/*{
				icon: 'notifications',
				name: 'This is a notification',
				time: 'few sec ago',
				read: false,
				colorClass: ''
				},
				{
				icon: 'shopping_basket',
				name: 'User bought your template',
				time: '23 min ago',
				read: false,
				colorClass: 'primary'
				},
				{
				icon: 'eject',
				name: 'Server Crashed',
				time: 'an hour ago',
				read: false,
				colorClass: 'accent'
				},
				{
				icon: 'cached',
				name: 'New user registered',
				time: '6 hours ago',
				read: true,
				colorClass: ''
				},
				{
				icon: 'code',
				name: 'John added you as friend',
				time: 'yesterday',
				read: true,
				colorClass: ''
			}*/
		];
		
		
					var nt = this.notifications;
			/*
			//this.cd.markForCheck();*/
			$.ajax({
				
				type:"GET",
				url: server+'getEventi.php',
				data: {
					id:'0'
				},
				dataType: "jsonp",
				jsonp: 'callback',
				jsonpCallback: 'getEventi',
				success: function(msg){
					var l= msg;
					if(l.length!=0){
						nt.unshift({
							icon: 'cached',
							name: "Contratto: "+l[0]['contratto']+' Revisione: '+l[0]['revisione'],
							time: moment().fromNow(),
							read: false,
							id:l[0]['fk_log'],
							colorClass: '',
						});
						Push.create("Nuova revisione!", {
							body: "Contratto: "+l[0]['contratto']+' Revisione: '+l[0]['revisione'],
							icon: "./assets/img/logo_dark2x.png",
							timeout: 1000,
							onClick: function() {
								console.log(this);
							}
					});
						//alert(l.length);
					
					}
				}
				
			});
			
			
		setInterval(() => {
			
			
			var nt = this.notifications;
			/*
			//this.cd.markForCheck();*/
			$.ajax({
				
				type:"GET",
				url: server+'getEventi.php',
				data: {
					id:'0'
				},
				dataType: "jsonp",
				jsonp: 'callback',
				jsonpCallback: 'getEventi',
				success: function(msg){
					var l= msg;
					if(l.length!=0){
						nt.unshift({
							icon: 'cached',
							name: "Contratto: "+l[0]['contratto']+' Revisione: '+l[0]['revisione'],
							time: moment().fromNow(),
							read: false,
							id:l[0]['fk_log'],
							colorClass: '',
						});
						Push.create("Nuova revisione!", {
							body: "Contratto: "+l[0]['contratto']+' Revisione: '+l[0]['revisione'],
							icon: "./assets/img/logo_dark2x.png",
							timeout: 1000,
							onClick: function() {
								console.log(this);
							}
					});
						//alert(l.length);
					
					}
				}
				
			});
			
		}, 1000*60*30);
		
		
	}
	
	markAsRead(notification) {
		notification.read = true;
	}
	
	dismiss(notification) {
	//alert(this.notifications.indexOf(notification)+' '+notification.id);
	this.notifications.splice(this.notifications.indexOf(notification), 1);
		var nt = this.notifications;
					$.ajax({
				
				type:"GET",
				url: server+'getEventi.php',
				data: {
					id:'2',
					guid:notification.id
				},
				dataType: "jsonp",
				jsonp: 'callback',
				jsonpCallback: 'getEventi',
				success: function(msg){
					var l= msg;
					alert('Notifica eliminata con successo!');
					
				}
				
			});
		// this.triggerDemoNotification();
	}
	
	toggleDropdown() {
		this.isOpen = !this.isOpen;
		// this.triggerDemoNotification();
		var nt = this.notifications;
		$.ajax({
				
				type:"GET",
				url: server+'getEventi.php',
				data: {
					id:'0'
				},
				dataType: "jsonp",
				jsonp: 'callback',
				jsonpCallback: 'getEventi',
				success: function(msg){
					var l= msg;
					if(l.length!=0){
						nt.unshift({
							icon: 'cached',
							name: "Contratto: "+l[0]['contratto']+' Revisione: '+l[0]['revisione'],
							time: moment().fromNow(),
							read: false,
							id:l[0]['fk_log'],
							colorClass: '',
						});
						Push.create("Nuova revisione!", {
							body: "Contratto: "+l[0]['contratto']+' Revisione: '+l[0]['revisione'],
							icon: "./assets/img/logo_dark2x.png",
							timeout: 1000,
							onClick: function() {
								console.log(this);
							}
					});
						//alert(l.length);
					
					}
				}
				
			});
	}
	
	onClickOutside() {
		this.isOpen = false;
	}
	
	triggerDemoNotification() {
		// if (this.demoTriggers === 0) {
		this.demoTriggers++;
		
		
		
		/*  setTimeout(() => {
			this.notifications.unshift({
			icon: 'cached',
			name: 'New user registered',
			time: moment().fromNow(),
			read: false,
			colorClass: '',
			});
			Push.create("Sanatec App", {
			body: "Questa è una web app!",
			icon: "./assets/img/logo_dark2x.png",
			timeout: 1000,
			onClick: function() {
            console.log(this);
			}
			});
			
			this.cd.markForCheck();
		}, 4000);*/
		/* } else if (this.demoTriggers === 1) {
			this.demoTriggers++;
			
			/* setTimeout(() => {
			this.notifications.unshift({
			icon: 'shopping_basket',
			name: 'User bought your template',
			time: '23 min ago',
			read: false,
			colorClass: 'primary'
			});
			
			this.cd.markForCheck();
			}, 2000);
		}*/
	}
}
