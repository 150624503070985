import { AfterViewInit, Component, OnInit } from '@angular/core';

@Component({
  selector: 'vr-toolbar-user-button',
  templateUrl: './toolbar-user-button.component.html',
  styleUrls: ['./toolbar-user-button.component.scss']
})
export class ToolbarUserButtonComponent implements OnInit, AfterViewInit {

  isOpen: boolean;
message:any;
  constructor() {   }

  ngOnInit() {
	  
	this.message = document.cookie;
  }

  ngAfterViewInit() {
	 //  var output2 =   document.getElementById("name");
	 // document.getElementById("name").innerHTML = "Marco";
	 
	  //console.log("aaaaa"+document.getElementById("name").innerHTML);
  }

  toggleDropdown() {
    this.isOpen = !this.isOpen;
  }

  onClickOutside() {
  
    this.isOpen = false;
  }
  
  prova(){
	 document.cookie = "user=;expires=" + new Date(0).toUTCString()+ ";path=/";
	  window.location.href = 'http://sanatec.mabesoft.cloud/login.php';
	  
	  }
}
